import { Component, OnInit } from '@angular/core';
import { RfcModalService } from '../../services/rfc-modal.service';

@Component({
  selector: 'app-modal-rfc',
  templateUrl: './modal-rfc.component.html',
  styleUrls: ['./modal-rfc.component.css']
})
export class ModalRfcComponent implements OnInit {
  modalCompany: boolean = false;
  isModalOpen: boolean = false;
  obAgentName: string;
  obAgentEmail: string;
  obAgentPhone: string;
  rfcInput: string;
  emailInput: string;
  constructor(public modalService: RfcModalService) { }

  ngOnInit(): void {
    this.modalService.modalOpen$.subscribe(input => {
      console.log(input)
      if (input.open) {
        input.entity == "company" ? this.modalCompany = true : this.modalCompany = false;
        this.isModalOpen = true;
        this.obAgentName = input.obAgentName;
        this.obAgentEmail = input.obAgentEmail;
        this.obAgentPhone = input.obAgentPhone;
        this.rfcInput = input.rfcInput;
        this.emailInput = input.emailInput;
        this.openModal();
      } else {
        this.isModalOpen = false;
        this.closeModal();
      }
    });
  }

  openModal() {
    // Aquí podrías añadir lógica adicional antes de abrir el modal
    document.body.classList.add('modal-open'); // Añade clase para evitar el scroll del fondo
  }

  closeModal() {
    // Aquí podrías añadir lógica adicional antes de cerrar el modal
    document.body.classList.remove('modal-open'); // Remueve la clase para permitir scroll del fondo
  }

}
