import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class GetRecordLandingService {
  private _env: string = this.env._env;
  private url = null;

  constructor(private http: HttpClient, private env: EnvironmentService) { }

  public getContalinkRecordLanding(getRecordLanding: GetRecordLanding): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders().set('X-Api-Key', environment[this._env]['back_end_api_token']);
    let params = new HttpParams().set("record_landing_id", getRecordLanding.record_landing_id);
    this.url = `${environment[this._env]['back_end_url']}/recordlanding`;

    return this.http.get(this.url, {
      headers: headers,
      params: params
    });
  }
}
export interface GetRecordLanding {
  record_landing_id: string
}