<notifier-container></notifier-container>
<mat-horizontal-stepper #stepper linear id="hzt_stepper">
  <mat-step [stepControl]="firstFormGroup" [completed]="stepOneCompleted">
    <form [formGroup]="firstFormGroup" >
      <ng-template matStepLabel>Usuarios y Planes</ng-template>
        <!-- P  R   I   M   E   R     P   A   S   O -->
        <section>
          <div class="row" [formGroup]="signupForm">
            <div class="users-form-bckgd">
              <div class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-6 col-sm-offset-3">
                <div class="users-form form-inline" role="form">
                  <mat-card>
                  </mat-card>
                </div>
              </div>
            </div>
          </div>
        </section>
  </form>
  </mat-step>
<!-- P  R   I   M   E   R     P   A   S   O     F   I   N   -->
  <mat-step [stepControl]="secondFormGroup" completed="stepTwoCompleted">    
    <form [formGroup]="secondFormGroup">
      <ng-template matStepLabel>Información de Registro</ng-template>
      <div class="users-form-bckgd">
        <div class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-6 col-sm-offset-3">
          <div class="users-form form-inline" role="form">
            <mat-card>
            </mat-card>
          </div>
        </div>
      </div>
    </form>


  <mat-step completed="stepPMethodCompleted">    
      <ng-template matStepLabel>Método de Pago</ng-template>
      <div class="users-form-bckgd">
        <div class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-6 col-sm-offset-3">
          <div class="users-form form-inline" role="form">
            <mat-card>
            </mat-card>
          </div>
        </div>
      </div>
  </mat-step>

<!-- S  E   G   U   N   D   O     P   A   S   O     F   I   N   -->
  </mat-step>
  <mat-step  completed="stepThreeCompleted">
    <ng-template matStepLabel>Información de Pago</ng-template>
    <div class="users-form-bckgd">
      <div class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-6 col-sm-offset-3">
        <p class="users-bg-img-text"></p>
        <div class="users-form form-inline" role="form">
          <mat-card>
          </mat-card>
        </div>
      </div>
    </div>
  </mat-step>    
<!-- T  E   R   C   E   R     P   A   S   O     F   I   N   -->
  <mat-step  completed="stepFourCompleted">
    <ng-template matStepLabel>Listo!</ng-template>

    <div class="users-form-bckgd" style="margin-bottom: 20em; margin-top: 2em;">
      <div class="main-container min-h-screen flex flex-col items-center justify-center relative pt-50 pb-8">
        <h1 class="title text-contalink-primary text-center text-4xl md:text-6xl lg:text-7xl font-bold animate-fade" *ngIf="!processingPayment">
          {{ pageTitle ? pageTitle : "¡Gracias por contactarnos!" }}
        </h1>
        <h2 *ngIf="pageSubtitle" class="title text-contalink-secondary text-center text-3xl md:text-5xl font-normal animate-fade">
           {{ pageSubtitle }}
        </h2>

        <div *ngIf="processingPayment" class="loader-overlay">
          <div class="loader">
            <h1>Falta poco...</h1>
            <div class="spinner text-center"></div>
          </div>
        </div>

        <div *ngIf="showExitoso" class="animate-fade">
          <p class="normal-text text-contalink-black text-xl text-center">
            Te invitamos a ver nuestro contenido de
            <a href="https://tutoriales.contalink.com/es/collections/6941920-primeros-pasos" target="_blank"
              rel="noopener noreferrer" class="link-hover font-medium">Introducción y primeros pasos</a>, con ello podrás
            conocer como funciona Contalink.
          </p>
          <p class="normal-text text-contalink-black text-xl text-center">
            Para dudas y soporte inicial puedes contactar a:
          </p>
        </div>

        <div *ngIf="showError" class="animate-fade">
          <p class="normal-text text-center text-contalink-black text-xl">
            Tu pago ha sido procesado pero hemos tenido un problema para activar tu
            cuenta. Por favor comunícate con nuestro equipo a través del correo
            <a href="mailto:info@contalink.com" class="link-hover font-semibold">info@contalink.com</a>
            o por WhatsApp al
            <a href="https://api.whatsapp.com/send/?phone=528119107569&text&type=phone_number&app_absent=0" target="_blank"
              rel="noopener noreferrer" class="link-hover font-semibold">81 19 10 75 69</a>
          </p>
        </div>

        <div *ngIf="showPendiente" class="animate-fade mb-16 text-center">
          <p class="normal-text text-contalink-black text-xl">
            En menos de 24 horas recibirás en tu correo la información para pagar en
            OXXO o SPEI.
          </p>
          <p class="normal-text text-contalink-black text-xl">
            Una vez efectuado el pago, se realizará la activación de tu cuenta. Si tienes cualquier duda por favor
            contactáctanos en:
          </p>
          <p class="normal-text text-contalink-black text-xl">
            <a href="mailto:info@contalink.com" class="link-hover font-semibold">info@contalink.com</a>
            o por WhatsApp al
            <a href="https://api.whatsapp.com/send/?phone=528119107569&text&type=phone_number&app_absent=0"
              class="link-hover font-semibold">811 910 7569</a>
          </p>
        </div>

        <div *ngIf="isRetry == true && LSError == true" id="stripe-retry-btn" class="animate-fade mb-16 text-center">
          <br>
          <p class="normal-text text-contalink-black text-xl">
              Ha habido un error al procesar tu pago. {{textLSStripeMessage}}<br>
              Favor de comunicarse con nuestro equipo vía info@contalink.com o por Whatsapp al +52 811 910 7569
              <br><br>
              También puedes intentar con otra tarjeta:<br><br>
          </p>
          <a class="btn_ingresar_a_mi_cuenta" href="https://signup.contalink.com/retry?setup_intent_client_secret={{textRetrySecret}}">INGRESAR OTRA TARJETA</a><br><br>          
        </div>

        <div *ngIf="showLite" class="animate-fade mb-16">
          <div class="flex items-center justify-center">
            <a href="https://app.contalink.com" target="_blank" rel="noopener noreferrer"
              class="btn btn-success py-4 px-12 text-white">Iniciar sesión</a>
          </div>
        </div>

        <div class="flex flex-col items-center justify-center animate-fade-up mt-5 text-center" style="margin-top: 5em;">
          <div *ngIf="showExitoso && (contactAgent || contactEmail || contactPhone)"
            class="rounded-2xl border border-contalink-muted shadow p-4 mb-16">
            <p class="normal-text text-contalink-accent text-3xl font-large">
              {{ contactAgent }}
            </p>
            <a class="link-hover inline-flex items-center gap-2 mb-4"
              href="mailto:{{ contactEmail }}">
                 <!-- <app-envelope></app-envelope>  -->
                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                 </svg>
                     {{ contactEmail }} 
                </a>
            <br />
            <a class="link-hover inline-flex items-center gap-2 mb-4" href="tel:{{ contactPhone }}">
              <!-- <app-telephone></app-telephone> -->
                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                   <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                 </svg>
               {{ contactPhone }}
              </a>
          </div>
        </div> 
      </div>
    </div>
  </mat-step>



</mat-horizontal-stepper>
<div class="row mt-10">
  <div class="col-lg-12 col-md-12 col-sm-12 text-center">
    <!--<a routerLink="/aviso-de-privacidad">Aviso de privacidad</a>-->
  </div>
</div>

