<br><br>
<div class="loading-div" id="loading-div-section" style="text-align:center;">
	<br><br>
	<img src="https://c.tenor.com/I6kN-6X7nhAAAAAi/loading-buffering.gif"
		style="width:5%;"/>
		<br><br>
	<p>Espere mientras cargamos la información de su pago...</p>
</div>
<section class="card-payment" id="card-payment-section">
	<mat-card>
		<h2><b>Su Pago</b></h2>
		<p>Cliente: {{bp_name}}</p>
		<p>Total: {{order_total}} MXN</p>
	    <form id="payment-form" >
	        <div id="payment-element"></div>
	          <button 
	            style="width:100%;background-color:#70B2E5;padding-top:0.6em;padding-bottom:0.6em;font-weight:bold;margin-bottom:1em;color:white;border:1px solid #70B2E5;"
	            id="submit" type="submit">Pagar ahora {{order_total}} MXN
	          </button>
	        <div id="error-message"></div>
	    </form>
	    <script src="https://js.stripe.com/v3/"></script>	
	</mat-card>
</section>
<br><br>
<!--
<h1>PAGOS</h1>
<h2>Cliente: {{bp_name}}</h2>
<h2>Total: {{order_total}}</h2>
-->