// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
    production: false,
    logo: 'assets/favtegik.png',
    bucket_files: 'contalink-files',
    PIPELINK_API_TOKEN: '0265cdd4911c8d00da7b508d60ec5171fa4b5e00',
    PIPELINK_API_URL: 'https://contalink.pipedrive.com/api/v1',
    META_API_URL: 'https://graph.facebook.com/v14.0',
    META_PIXEL_ID: '553320291959158',
    META_API_TOKEN: 'EAATIdAbJnq0BOZCVokZAOtrXwSCbqceofQYuZCsJ9xHPprjrLMIZBqag4GI34P6LrL82MqZC7pqMTPcZAolsj4EsJKcAYN3WWFdZCktnHoUyPEMHyxR4cnThCqbVJFE0vuQcMTQq1I1r4oLlet2hz6nVJW0HHlYIgBsZAcDCYcpgLezPN2a29CgwaFryPkdZBVhrRfAZDZD',
    api_token_backend: 'J5rfNHyuqg2r8L4OcdzGV5vESnhoWdGQ80jiAI5G',

    dev: {
        api_endpoint: '/dev',
        bucket_upload: 'cl-dev-fileprocessing',
        rails_service: '/rails_dev',
        socket: 'wss://i2haoju2y4.execute-api.us-east-1.amazonaws.com/qa',
        STRIP_API_KEY_LIVE: 'pk_test_51I1w3sADOnlYtR5gNjM1dJqXNgetIl43jUppmJfZaX0JDzMTZrd8TMKgXIzUdYJrTZeNbsqljHPAMKkTQ3acQ7YB008NsdAfSg',
        STRIPE_SECRET_KEY_LIVE: 'sk_test_51I1w3sADOnlYtR5gF7c1AYmieWFE7aOqWBYpqqJLYxXWaINFtFXhp3BMMsOnMDCP1StupNHjPVkvoxxkYJRY7nrX004xzUyBcF',
        url: 'http://localhost:4200/',
        back_end_url: 'https://34ltgz05j4.execute-api.us-east-1.amazonaws.com/qa/cl-freemium-signup-backend',
        back_end_api_token: 'J5rfNHyuqg2r8L4OcdzGV5vESnhoWdGQ80jiAI5G'
    },
    prod: {
        api_endpoint: '/prod',
        bucket_upload: 'cl-prod-fileprocessing',
        rails_service: '/rails_prod',
        socket: 'wss://i2haoju2y4.execute-api.us-east-1.amazonaws.com/production',
        STRIP_API_KEY_LIVE: 'pk_live_51I1w3sADOnlYtR5g8VWlyo3QjRsS2yC60uWAJNlRUvUf4NTGRNXIwThkqOfUugOkx4sziArfNynGH5lGjYEEeLS300HTOhznJu',
        STRIPE_SECRET_KEY_LIVE: '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',
        url: 'https://signup.contalink.com',
        back_end_url: 'https://34ltgz05j4.execute-api.us-east-1.amazonaws.com/prod/cl-freemium-signup-backend',
        back_end_api_token: 'J5rfNHyuqg2r8L4OcdzGV5vESnhoWdGQ80jiAI5G'
    },
    qa: {
        api_endpoint: '/qa',
        bucket_upload: 'cl-dev-fileprocessing',
        rails_service: '/rails_qa',
        socket: 'wss://i2haoju2y4.execute-api.us-east-1.amazonaws.com/qa',
        STRIP_API_KEY_LIVE: 'pk_test_51I1w3sADOnlYtR5gNjM1dJqXNgetIl43jUppmJfZaX0JDzMTZrd8TMKgXIzUdYJrTZeNbsqljHPAMKkTQ3acQ7YB008NsdAfSg',
        STRIPE_SECRET_KEY_LIVE: 'sk_test_51I1w3sADOnlYtR5gF7c1AYmieWFE7aOqWBYpqqJLYxXWaINFtFXhp3BMMsOnMDCP1StupNHjPVkvoxxkYJRY7nrX004xzUyBcF',
        url: 'http://qasignup.contalink.com.s3-website-us-east-1.amazonaws.com/',
        back_end_url: 'https://34ltgz05j4.execute-api.us-east-1.amazonaws.com/qa/cl-freemium-signup-backend',
        back_end_api_token: 'J5rfNHyuqg2r8L4OcdzGV5vESnhoWdGQ80jiAI5G'
    },
    staging: {
        api_endpoint: '/staging',
        bucket_upload: 'cl-dev-fileprocessing',
        rails_service: '/rails_staging',
        socket: 'wss://i2haoju2y4.execute-api.us-east-1.amazonaws.com/staging',
    },
    api_reports: '/api_reports'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
