import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GetPlansService {
  private _env: string = this.env._env;
  private url = null;

  constructor(private http: HttpClient, private env: EnvironmentService) { }

  public getContalinkPlans(getPlans: GetPlans): Observable<any> {

    let headers: HttpHeaders = new HttpHeaders().set('X-Api-Key', environment[this._env]['back_end_api_token']);
    this.url = `${environment[this._env]['back_end_url']}/getplans`;

    return this.http.get(this.url, {
      headers: headers
    });
  }
}
export interface GetPlans {

}