import { Component, OnInit, ViewChild } from '@angular/core';
import { GetOrderInfoService } from '../../services/get-order-info.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NotifierService } from "angular-notifier";
import { loadStripe } from '@stripe/stripe-js/pure';
import { environment } from 'src/environments/environment';
import { EnvironmentService } from 'src/app/services/environment.service';


@Component({
	selector: 'app-users',
	templateUrl: './payment.component.html',
	styleUrls: ['./payment.component.css'],
	providers: []
})


export class PaymentComponent implements OnInit {
	_env: string = this.env._env;
	elements: any;
	stripe: any;
	stripe_api_key: string;
	stripe_secret_key: string;
	client_secret: string;
	orderInfo: Promise<any> = null;
	payment_order_id: string = null;
	bp_name: Promise<any> = null;
	order_total: Promise<any> = null;
	private routeSub: Subscription;
	private readonly notifier: NotifierService;

	constructor(
		public GetOrderInfo: GetOrderInfoService,
		private route: ActivatedRoute,
		notifierService: NotifierService,
		private env: EnvironmentService
	) {
		this.notifier = notifierService;
	}

	async ngOnInit() {
		this.routeSub = this.route.params.subscribe(params => {
			this.payment_order_id = params['id']
		});
		this.orderInfo = await this.getOrderInfo();
		this.bp_name = this.orderInfo['order_info']['bpartner_name'];
		this.order_total = this.orderInfo['order_info']['total'];

		// Cargar Stripe Form
		this.stripe_api_key = environment[this._env]['STRIP_API_KEY_LIVE'];
		this.stripe_secret_key = environment[this._env]['STRIP_SECRET_API_KEY_LIVE'];
		this.client_secret = this.orderInfo['order_info']['bpartner_client_secret'];
		const form = document.getElementById('payment-form');
		this.stripe = loadStripe(this.stripe_api_key);
		const options = { clientSecret: this.client_secret };
		this.elements = (await this.stripe).elements(options);
		const paymentElement = this.elements.create('payment');
		paymentElement.mount('#payment-element');
		// http://localhost:4200/
		// https://signup.contalink.com/

		form.addEventListener('submit', async (event) => {
			event.preventDefault();
			const errorStripe = (await this.stripe).confirmSetup({
				elements: this.elements,
				confirmParams: {
					return_url: `${environment[this._env]['url']}/payoutss`
				}
			}).then((data) => {
				const messageContainer = document.querySelector('#error-message');
				if (data.hasOwnProperty("error")) {
					if (data.error.hasOwnProperty("message")) {
						let errorMessagePopUp = JSON.stringify(data.error.message) || "Ha habido un error al procesar su petición.";
						//alert(errorMessagePopUp);
						messageContainer.innerHTML = '<p style="color:#DF1B41;">' + errorMessagePopUp.replace(/^"|"$/g, '') + '</p>';
					}
				}
			});

			if (errorStripe) {
				console.log("$> ", errorStripe)
				const messageContainer = document.querySelector('#error-message');
				messageContainer.textContent = errorStripe.message;
				//messageContainer.innerHTML = '<p style="color:#DF1B41;">Ha habido un error al procesar su petición.</p>';
			} else {
				// Your customer will be redirected to your `return_url`. For some payment
				// methods like iDEAL, your customer will be redirected to an intermediate
				// site first to authorize the payment, then redirected to the `return_url`.
			}
		});
		// Hide Loader / display form
		let load_div = document.getElementById("loading-div-section") as HTMLElement;
		let payment_section = document.getElementById("card-payment-section") as HTMLElement;
		load_div.style.display = "none";
		payment_section.style.display = "block";
	}

	ngOnDestroy() {
		this.routeSub.unsubscribe();
	}

	async getOrderInfo() {
		return await this.GetOrderInfo.getContalinkOrderInfo({
			payment_order_id: this.payment_order_id
		}).toPromise();
	}
}
