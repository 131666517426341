import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { environment } from 'src/environments/environment';
import { IPostSlack } from '../interfaces/postslack.interface';

@Injectable({
  providedIn: 'root'
})
export class PostSlackService {
  private _env: string = this.env._env;
  private url = null;

  constructor(private http: HttpClient, private env: EnvironmentService) { }

  public postContalinkSlack(postSlack: IPostSlack): Observable<any> {

    //let headers: HttpHeaders = new HttpHeaders().set('X-Api-Key', this.api_token);
    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Api-Key': environment[this._env]['back_end_api_token']
    })

    this.url = `${environment[this._env]['back_end_url']}/postslack`;

    return this.http.post<any>(this.url,
      postSlack,
      {
        headers: headers
      })
  }
}