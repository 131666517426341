<!--<div class="custom-modal" *ngIf="modalService.modalOpen$ | async">-->
<div class="custom-modal" *ngIf="isModalOpen">  
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg" *ngIf="modalCompany">


    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">RFC Existente</h5>
      </div>
      <div class="modal-body">
        <p class="subtitle">El RFC registrado ya existe en Contalink y no es posible duplicarlo. Para ayudarte a crear tu cuenta, por favor elige:</p>
        <div class="options">
          <div class="option">
            <p>Contactar con un ejecutivo para ayudarte a operar esta empresa</p>
            <a (click)="modalService.closeModal()" href="https://api.whatsapp.com/send?phone={{ obAgentPhone }}&text=Hola%20estoy%20intentando%20adquirir%20una%20suscripción%20pero%20mi%20RFC%20({{ rfcInput }})%20ya%20existe%20en%20Contalink.%20Correo:%20{{ emailInput }}" target="_blank">Ir a WhatsApp</a>
          </div>
          <div class="option">
            <p>Modifica tus datos. El RFC que nos proporciones se utilizará tanto para facturar tu suscripción como para registrar la primer empresa con la que operarás en el sistema).</p>
            <a href="#" (click)="modalService.closeModal()">Regresar</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="small-modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg" *ngIf="!modalCompany">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Cliente Existente</h5>
      </div>
      <div class="modal-body">
        <div class="options">
          <div class="option">
              <p class="subtitle">Su RFC actualmente es un cliente activo en Contalink y recibe facturas de suscripción. Si deseas contratar usuarios o una cuenta adicional favor de comunicarse con su ejecutivo de cuenta.<br>
              <br>{{ obAgentName }}
              <br>{{ obAgentPhone }}
              <br>{{ obAgentEmail }}
              <br>
            </p>
            <a href="#" (click)="modalService.closeModal()">Regresar</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>