import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  public _env: string;
  private url_path: string = window.location.href;
 
  constructor() {
    if (this.url_path.includes("//signup.contalink.com")) this._env = 'prod';
    else if (this.url_path.includes("//localhost")) this._env = 'dev';
    else this._env = 'qa';
  }
}