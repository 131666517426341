import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { environment } from 'src/environments/environment';
import { ICheckCompany } from '../interfaces/checkcompany.interface';
import { IPostSlack } from '../interfaces/postslack.interface';

@Injectable({
  providedIn: 'root'
})
export class CheckCompanyService {
  private _env: string = this.env._env;
  private url = null;

  constructor(private http: HttpClient, private env: EnvironmentService) { }

  public checkContalinkCompany(checkCompany: ICheckCompany, data: IPostSlack): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders().set('X-Api-Key', environment[this._env]['back_end_api_token']);
    let params = new HttpParams()
      .set("rfc", checkCompany.rfc)
      .set("email", checkCompany.email)
      .set("phone", checkCompany.phone)
      .set("data", JSON.stringify(data));

    this.url = `${environment[this._env]['back_end_url']}/validatecompany`;

    return this.http.get(this.url, {
      headers: headers,
      params: params
    });
  }
}
