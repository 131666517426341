<br><br>
<div class="loading-div" id="loading-retry-section" style="text-align:center;">
  <br><br>
  <img src="http://3dbionotes-ws.cnb.csic.es/images/loading.gif"
    style="width:5%;"/>
    <br><br>
  <p>Espere mientras cargamos la información de su pago...</p>
</div>
<section class="card-payment" id="card-retry-section" style="display:none; margin-bottom: 25em;">
  <div class="row" [formGroup]="signupForm">
    <div class="users-form-bckgd">
      <div class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-6 col-sm-offset-3">
        <div class="users-form form-inline" role="form">
          <mat-card>
            <h2><b>Su Pago</b></h2>
            <p>Cliente: {{bp_name}}</p>
            <p>Suscripción: {{plan_name}}</p>
            <p>Total: {{order_total}} MXN</p>
              <form id="payment-form" >
                  <div id="payment-element"></div>
                    <button 
                      style="width:100%;background-color:#70B2E5;padding-top:0.6em;padding-bottom:0.6em;font-weight:bold;margin-bottom:1em;color:white;border:1px solid #70B2E5;"
                      id="submit" type="submit">Pagar ahora {{order_total}} MXN
                    </button>
                  <div id="error-message"></div>
              </form>
              <script src="https://js.stripe.com/v3/"></script> 
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</section>