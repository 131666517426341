import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PostLogTransactionService {
  private _env: string = this.env._env;
  private url = null;

  constructor(private http: HttpClient, private env: EnvironmentService) {}

  public postContalinkLogTransaction(postLogTransaction: PostLogTransaction): Observable<any> {

    //let headers: HttpHeaders = new HttpHeaders().set('X-Api-Key', this.api_token);
    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'X-Api-Key': environment[this._env]['back_end_api_token']
    })

    this.url = `${environment[this._env]['back_end_url']}/postlogtransaction`;
    
    return this.http.post<any>(this.url, 
    postLogTransaction,
    {
      headers: headers
    })
  }
}
export interface PostLogTransaction {
    action_key: string,
    browsertime: string,
    session_id: string,
    record_landing_id: number
}
