import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RfcModalService {
  private modalOpenSubject = new BehaviorSubject<any>({"open":false, "entity":"company"});
  modalOpen$ = this.modalOpenSubject.asObservable();

  constructor() { }

  openModal(entity: any) {
    this.modalOpenSubject.next({
      "entity": entity.entity,
      "open": true,
      "obAgentName": entity.obAgentName,
      "obAgentEmail": entity.obAgentEmail,
      "obAgentPhone": entity.obAgentPhone,
      "rfcInput": entity.rfcInput,
      "emailInput": entity.emailInput
    });
  }

  closeModal() {
    this.modalOpenSubject.next(false);
  }
}

