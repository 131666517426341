import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SendFormContactService } from 'src/app/services/send-form-contact.service';

@Component({
  selector: 'app-whatsapp-cta',
  templateUrl: './whatsapp-cta.component.html',
  styleUrls: ['./whatsapp-cta.component.css']
})
export class WhatsappCTAComponent implements OnInit {

  constructor(
    private sendFormContactService: SendFormContactService,
  ) { }

  ngOnInit(): void {
  }

  sendMetaAPI() {
    let $metaApi: Observable<any> = this.sendFormContactService.sendIntegrationAPI('API Click WhatsApp CTA')

    $metaApi.subscribe(() => {})
  }
}
