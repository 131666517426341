<notifier-container></notifier-container>
<section>
  <div class="row" [formGroup]="confirmPhoneForm">
    <div class="contact-form-bckgd">
      <div class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-6 col-sm-offset-3">
        <p class="contact-bg-img-text">Confirma tu celular</p>
        <div class="contact-form form-inline" role="form">
          <div class="row">
            <p for="confirmation_code" class="label-contact-field">Ingresa el código que hemos enviado por mensaje de texto a tu celular  *</p>
            <br>
            <input class="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-field" formControlName="confirmation_code"
              [ngClass]="{'': confirmPhoneFormControl.confirmation_code.valid && is_valid, 'required': confirmPhoneFormControl.confirmation_code.invalid && !is_valid }">
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <button id="confirm_phone_form" class="contact-button" (click)="confirmPhone()"
                [disabled]="disabled_button">Confirmar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>