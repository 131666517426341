import { Injectable } from "@angular/core";
import { UserService } from '../users/users.service';
import confetti from 'canvas-confetti';


@Injectable({
    providedIn: 'root'
})

export class CheckService {

    private url_path: string = window.location.href;

    constructor(
        private userService: UserService
    ) { }

    public addLogTransaction(text: string, landing_record): void {
        this.userService.addLogTransaction(text, landing_record, null);
    }

    public addMixPanelEvent(action, params) {
        this.userService.addMixPanelEvent(action, params);
    }

    public async launchConfetti() {
        confetti({ particleCount: 100, spread: 160 });
    }
}