import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SendSignupDataService} from '../../services/send-signup-data.service';
import { NotifierService } from "angular-notifier";
import { PixelService } from 'ngx-pixel'
import { ActivatedRoute, Router } from '@angular/router';
import { Data } from "../../providers/data/data";

@Component({
  selector: 'app-confirm_phone',
  templateUrl: './confirm_phone.component.html',
  styleUrls: ['./confirm_phone.component.css'],
  providers: [SendSignupDataService]
})
export class ConfirmPhoneComponent implements OnInit {

  fieldTextType: boolean = false;
  signupRequest: object;

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  public confirmPhoneForm: FormGroup = new FormGroup({
    confirmation_code: new FormControl('', [Validators.required, Validators.pattern("^.{8}$")]),
  })

  public is_valid: boolean = true;
  private readonly notifier: NotifierService;

  disabled_button: boolean = false;

  constructor(public sendSignupData: SendSignupDataService,
    notifierService: NotifierService,
    private pixel: PixelService,
    private route: ActivatedRoute,
    private router: Router,
    private data: Data) {
    this.notifier = notifierService;

  }

  ngOnInit() {
    console.log(this.data.storage);
    console.log(this.data.storage.name);
  }

  async confirmPhone() {
    this.disabled_button = true;
    this.is_valid = this.confirmPhoneForm.valid;

    if (this.is_valid) {

      let phone_register_response: any = await this.sendSignupData.signupCustomer({
        name: this.data.storage.name,
        email: this.data.storage.email,
        userpass: this.data.storage.userpass,
        phone: this.data.storage.phone,
        razon_social: this.data.storage.razon_social,
        rfc: this.data.storage.rfc,
        type_client: this.data.storage.type_client,
        accountants_number: this.data.storage.accountants_number,
        customer_source: this.data.storage.customer_source,
        confirmationcode: this.confirmPhoneFormControl.confirmation_code.value
      }).toPromise();

      if (phone_register_response.status == 1){
        this.notifier.notify("success", "Gracias por tu información. En breve nos pondremos en contacto contigo");
        this.confirmPhoneForm.reset();
      }
      else
      {
        this.notifier.notify("error", phone_register_response.message);
        this.confirmPhoneForm.reset();
      }

      this.disabled_button = false;

    } else {
      if (this.confirmPhoneForm.get('confirmation_code').invalid == true) {
        this.notifier.notify("error", "El código de confirmación debe ser a 8 dígitos");
      }
      this.disabled_button = false;
    }

  }

  get confirmPhoneFormControl() {
    return this.confirmPhoneForm.controls;
  }

}
