<notifier-container></notifier-container>
<section>
  <div class="row" [formGroup]="signupForm">
    <div class="contact-form-bckgd">
      <div class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-6 col-sm-offset-3">
        <p class="contact-bg-img-text">Regístrate</p>
        <div class="contact-form form-inline" role="form">
          <div class="row">
            <label for="name" class="label-contact-field">Nombre completo *</label>
            <br>
            <input class="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-field" formControlName="name"
              [ngClass]="{'': signupFormControl.name.valid && is_valid, 'required': signupFormControl.name.invalid && !is_valid }">
          </div>
          <div class="row">
            <br>
            <label for="name" class="label-contact-field">Correo electrónico *</label>
            <br>
            <input class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-field" type="text" formControlName="email"
              [ngClass]="{'': signupFormControl.email.valid && is_valid, 'required': signupFormControl.email.invalid && !is_valid }">
          </div>
          <div class="row">
            <br>
            <label for="name" class="label-contact-field">Contaseña *</label>
            <br>
            <input class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pwd-field" [type]="fieldTextType ? 'text' : 'password'"  formControlName="userpass"
              [ngClass]="{'': signupFormControl.userpass.valid && is_valid, 'required': signupFormControl.userpass.invalid && !is_valid }">
              <mat-icon class="password-toggle" (click)="toggleFieldTextType()">{{fieldTextType ? 'visibility' : 'visibility_off'}}</mat-icon>
          </div>
          <div class="row">
            <br>
            <label for="name" class="label-contact-field">Celular *</label>
            <br>
            <input class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-field" type="tel" formControlName="phone"
              [ngClass]="{'': signupFormControl.phone.valid && is_valid, 'required': signupFormControl.phone.invalid && !is_valid }">
          </div>
          <div class="row">
            <br>
            <label for="type_client" class="label-contact-field">Razón Social a descargar * </label>
            <input class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-field" type="text" formControlName="razon_social"
              [ngClass]="{'': signupFormControl.razon_social.valid && is_valid, 'required': signupFormControl.razon_social.invalid && !is_valid }">
          </div>
          <div class="row">
            <br>
            <label for="type_client" class="label-contact-field">RFC a descargar *</label>
            <input class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-field" type="text" formControlName="rfc"
              [ngClass]="{'': signupFormControl.rfc.valid && is_valid, 'required': signupFormControl.rfc.invalid && !is_valid }">
              <br>
              <label for="type_client" class="label-contact-field">**  Esta será la primera empresa a descargar. Después podrás agregar tantas como necesites</label>
              <br>
          </div>
          <div class="row">
            <br>
            <label class="label-contact-field" for="type_client">¿Que opción te describe mejor? *</label>
            <br>
            <select name="type_client" id="type_client" class="custom-select">
              <optgroup class="label-contact-field">
                <option value=''></option>
                <option value="DC">Soy un contador dentro de un despacho contable</option>
                <option value="DCPYME">Trabajo en el departamento contable de una empresa</option>
                <option value="PYME">Soy una PYME</option>
                <option value="PI">Soy un profesionista independiente</option>
              </optgroup>>
            </select>
          </div>
          <div class="row">
            <br>
            <label for="accountants_number" class="label-contact-field">Número de contadores o auxiliares, incluyendo a usted. *</label>
            <input class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-field" type="number" formControlName="accountants_number"
              [ngClass]="{'': signupFormControl.accountants_number.valid && is_valid, 'required': signupFormControl.accountants_number.invalid && !is_valid }">
          </div>
          <div class="row">
            <br>
            <label class="label-contact-field" for="customer_source">¿Cómo te enteraste de nosotros? *</label>
            <br>
            <select name="customer_source" id="customer_source" class="custom-select">
              <optgroup class="label-contact-field">
                <option value=''></option>
                <option value="GOOGLESEARCH">Búsqueda en Google</option>
                <option value="FB ADS">Anuncio en Facebook</option>
                <option value="GOOGLE ADS">Anuncio en otro sitio Web</option>
                <option value="IMCP">IMCP</option>
                <option value="EL CONTRIBUYENTE">El Contribuyente</option>
                <option value="FORBES">Forbes</option>
                <option value="REFERENCIA">Referencia de conocido / colega</option>
                <option value="OTRO">Otro</option>
              </optgroup>>
            </select>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <button id="send_contact_form" class="contact-button" (click)="enviar()"
                [disabled]="disabled_button">Enviar</button>
            </div>
          </div>
          <div class="row mt-10">
            <div class="col-lg-12 col-md-12 col-sm-12 text-center">
              <a routerLink="/aviso-de-privacidad">Aviso de privacidad</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>