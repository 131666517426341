import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PostSlackStripeService {
  private _env: string = this.env._env;
  private url = null;

  constructor(private http: HttpClient, private env: EnvironmentService) { }

  public postContalinkSlackStripe(postSlackStripe: PostSlackStripe): Observable<any> {

    //let headers: HttpHeaders = new HttpHeaders().set('X-Api-Key', this.api_token);
    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Api-Key': environment[this._env]['back_end_api_token']
    })

    this.url = `${environment[this._env]['back_end_url']}/slackstripe`;

    return this.http.post<any>(this.url,
      postSlackStripe,
      {
        headers: headers
      })
  }
}
export interface PostSlackStripe {
  name: string,
  email: string,
  phone: string,
  rfc: string,
  razon_social: string,
  rfc_facturacion: string,
  razon_social_facturacion: string,
  numero_usuarios: string,
  plan_id: string,
  total: string,
  discount: string,
  userpass: string,
  regimenName: string,
  regimen: string,
  postal_code: string,
  fuente: string,
  fuenteName: string,
  precio_deal: string,
  descuento_deal: string,
  pipedrive_id: string
}