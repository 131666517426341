import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RegisterPhoneService } from '../../services/register-phone.service';
import { NotifierService } from "angular-notifier";
import { Router } from '@angular/router';
import { Data } from "../../providers/data/data";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']})
export class ContactComponent implements OnInit {

  fieldTextType: boolean = false;

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  company_type_options = [
    { label: '', id: null },
    { label: 'Despacho Contable', id: 'DC' },
    { label: 'Departamento Contable en PyME', id: 'DCPYME' }
  ];

  public signupForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.nullValidator]),
    email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
    userpass: new FormControl('', [Validators.required, Validators.pattern("^.{6,}$")]),
    phone: new FormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    razon_social: new FormControl('', [Validators.required, Validators.nullValidator]),
    rfc: new FormControl('', [Validators.required, Validators.pattern("^[A-Z,Ñ,&]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z0-9]{3}")]),
    type_client: new FormControl(),
    accountants_number: new FormControl('', [Validators.required, Validators.nullValidator]),
    customer_source: new FormControl()
  })

  public is_valid: boolean = true;

  private readonly notifier: NotifierService;

  disabled_button: boolean = false;

  constructor(public registerPhone: RegisterPhoneService,
    notifierService: NotifierService,
    private router: Router,
    private data: Data) {
    this.notifier = notifierService;

  }

  ngOnInit() {

  }

  async enviar() {
    this.disabled_button = true;
    this.is_valid = this.signupForm.valid;
    var e = (document.getElementById("type_client")) as HTMLSelectElement;
    var sel = e.selectedIndex;
    var opt = e.options[sel];
    var type_client_v = (<HTMLOptionElement>opt).value; var selText = (<HTMLOptionElement>opt).text;
    console.log("type_client", type_client_v)
    if (type_client_v == ''){
      this.is_valid = false
      this.notifier.notify("error", "Por favor indícanos lo que mejor describe tu situación laboral");
    }

    var e = (document.getElementById("customer_source")) as HTMLSelectElement;
    var sel = e.selectedIndex;
    var opt = e.options[sel];
    var customer_source_v = (<HTMLOptionElement>opt).value; var selText = (<HTMLOptionElement>opt).text;
    console.log("customer_source_v", customer_source_v)
    if (customer_source_v == ''){
      this.is_valid = false
      this.notifier.notify("error", "Por favor indicanos cómo te enteraste de nosotros");
    }

    if (this.is_valid) {

      this.data.storage = {
        "name": this.signupFormControl.name.value,
        "email": this.signupFormControl.email.value,
        "userpass": this.signupFormControl.userpass.value,
        "phone": this.signupFormControl.phone.value,
        "razon_social": this.signupFormControl.razon_social.value,
        "rfc": this.signupFormControl.rfc.value,
        "type_client": type_client_v,
        "accountants_number": this.signupFormControl.accountants_number.value,
        "customer_source": customer_source_v
      };

      this.disabled_button = true;

      let phone_register_response: any = await this.registerPhone.registerCustomerPhone({
        phonenumber: this.signupFormControl.phone.value
      }).toPromise();

      console.log(phone_register_response)

      this.router.navigate(['confirmar_telefono']);

      this.disabled_button = false;

    } else {
      if (this.signupForm.get('email').invalid == true) {
        this.notifier.notify("error", "El correo electrónico es incorrecto");
      }
      if (this.signupForm.get('name').invalid == true) {
        this.notifier.notify("error", "El campo de nombre es obligatorio");
      }
      if (this.signupForm.get('phone').invalid == true) {
        this.notifier.notify("error", "El campo de teléfono debe ser a 10 dígitos, sin espacios ni otros caracteress");
      }
      if (this.signupForm.get('razon_social').invalid == true) {
        this.notifier.notify("error", "El campo de razón social es obligatorio");
      }
      if (this.signupForm.get('rfc').invalid == true) {
        this.notifier.notify("error", "El rfc debe tener el formato correcto, en mayúsculas y sin espacios");
      }
      if (this.signupForm.get('userpass').invalid == true) {
        this.notifier.notify("error", "El password debe tener al menos 6 caractéres");
      }
      if (this.signupForm.get('accountants_number').invalid == true) {
        this.notifier.notify("error", "Por favor indica el número de contadores en tu despacho o práctica contable");
      }

      this.disabled_button = false;
    }

  }

  get signupFormControl() {
    return this.signupForm.controls;
  }

}
