import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './pages/contact/contact.component';
import { UsersComponent } from './pages/users/users.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { RetryComponent } from './pages/retry/retry.component';
import { CheckoutPaymentComponent } from './pages/checkout_payment/checkout_payment.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { ConfirmPhoneComponent } from './pages/confirm_phone/confirm_phone.component';
import { Router, ActivatedRoute } from '@angular/router';
import { MainComponent } from './main/main.component';


const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [{
      path: '',
      component: UsersComponent
    },
    {
      path: '',
      redirectTo: '',
      pathMatch: 'full'
    }, {
      path: 'contacto',
      component: ContactComponent
    }, {
      path: 'referidos/:id',
      component: UsersComponent
    },
     { 
      path: 'users',
      component: UsersComponent
    }, { 
      path: 'listoss',
      component: CheckoutComponent
    }, {
      path: 'payment/:id',
      component: PaymentComponent
    }, { 
      path: 'payoutss',
      component: CheckoutPaymentComponent
    }, {
      path: 'retry',
      component: RetryComponent
    },
    {
      path: 'confirmar_telefono',
      component: ConfirmPhoneComponent
    }]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private router: Router) {
    this.router.errorHandler = (error: any) => {
      this.router.navigate(['inicio']); // or redirect to default route
    }
  }

}
