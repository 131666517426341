// Dependencies
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class SendFormContactService {
  private personResponse: any;

  constructor(private http: HttpClient, private cookieService: CookieService) {}

  sendIntegrationAPI(
    event_name: string = 'API Contacto Exitoso',
    pixel_id: string = `${environment.META_PIXEL_ID}`, 
    token: string = `${environment.META_API_TOKEN}`
  ) {
    return this.http.post(
      `https://graph.facebook.com/v18.0/${pixel_id}/events?access_token=${token}`,
      {
        data: [
          {
            event_name,
            event_time: Math.floor(Date.now() / 1000),
            action_source: 'website',
            "user_data": {
              "em": [
                null
              ],
              "ph": [
                null
              ]
            },
          },
        ],
        // "test_event_code": "TEST54276"
      }
    );
  }
}
